import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './features/account/login/login.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { Interceptor } from './core/services/interceptor';
import { CheckboxModule } from 'primeng/checkbox';
import { PasswordModule } from 'primeng/password';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { ReactiveFormsModule } from '@angular/forms';
import { ForgetPasswordComponent } from './features/account/forget-password/forget-password.component';
import { AuthPageComponent } from './features/account/auth-page/auth-page.component';
import { OtpComponent } from './features/account/otp/otp.component';
import { ResetPasswordComponent } from './features/account/reset-password/reset-password.component';
import { SharedModule } from "./shared/shared.module";
import { PageNotfoundComponent } from './features/account/page-notfound/page-notfound.component';
import { ChangePasswordDialogComponent } from './features/account/components/change-password-dialog/change-password-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ForgetPasswordComponent,
        AuthPageComponent,
        OtpComponent,
        ResetPasswordComponent,
        PageNotfoundComponent,
        ChangePasswordDialogComponent,
    ],
    providers: [MessageService, ConfirmationService, DialogService, Interceptor, { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        InputTextModule,
        ButtonModule,
        ChipModule,
        ReactiveFormsModule,
        HttpClientModule,
        ToastModule,
        CheckboxModule,
        PasswordModule,
        OverlayPanelModule,
        SharedModule
    ]
})
export class AppModule { }

<form [formGroup]="usersForm">
    <p-dialog [styleClass]="'popup_export'" [draggable]="false" [closable]="false" [style]="{'width': '25%'}"
        [modal]="true" [visible]="visible">

        <ng-template pTemplate="header">
            <div class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted">
                <span class="text-xl font-bold">Set New Password</span>
            </div>
        </ng-template>


        <div class="grid" *ngIf="usersForm">
            <div class="col-12 md:col-12 lg:col-12 xl:col-12 relative">
                <acv-password-input [form]="usersForm" ControlName="password" [ControlLabelText]="'Password'"
                    [PlaceHolderText]="'Enter Password'" [Required]="true" [overlay]="false" [ShowWordCount]="false"
                    (iconClick)="PassEyeIconHide = !PassEyeIconHide"
                    [IconClass]="PassEyeIconHide ? 'pi-eye-slash' : 'pi-eye'" [IconPosition]="'right'" [IsSubmited]="
                !usersForm.controls['password'].valid &&
                is_form_submit &&
                !resp?.error
              ">
                </acv-password-input>
                <div class="error-message"
                    *ngIf="usersForm.controls['password']?.invalid && (usersForm.controls['password']?.touched || usersForm.controls['password']?.dirty)">
                    <ul>
                        <li style="margin-top: 5px;" *ngFor="let message of getErrorMessages()">{{ message }}</li>
                    </ul>
                </div>
            </div>

            <div class="col-12 md:col-12 lg:col-12 xl:col-12">
                <acv-password-input [form]="usersForm" ControlName="confirm_password"
                    [ControlLabelText]="'Confirm Password'" [PlaceHolderText]="'Enter Password'" [Required]="true"
                    [ShowWordCount]="false" (iconClick)="confirmPassEyeIconHide = !confirmPassEyeIconHide"
                    [IconClass]="confirmPassEyeIconHide ? 'pi-eye-slash' : 'pi-eye'" [IconPosition]="'right'"
                    [IsSubmited]="
                !usersForm.controls['confirm_password'].valid &&
                is_form_submit &&
                !resp?.error
              "></acv-password-input>
                <div class="error-message"
                    *ngIf="(usersForm.controls['password'].value !== usersForm.controls['confirm_password'].value) && (usersForm.controls['confirm_password']?.touched || usersForm.controls['confirm_password']?.dirty)">
                    <p>Password not matched</p>
                </div>
            </div>
        </div>

        <div class="dialog_footer mt-4">
            <div class="flex align-items-center flex justify-content-center">
                <p-button type="button" label="Submit"
                    [disabled]="usersForm.invalid || usersForm.controls['password'].value !== usersForm.controls['confirm_password'].value"
                    class="p-button-text elevated-btn primary mr-2" (click)="submit()"></p-button>
            </div>
        </div>
    </p-dialog>
</form>
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem } from 'primeng/api';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';
import { ApienvService } from 'src/app/core/services/apienv.service';
import { DataResolverService } from 'src/app/core/services/data-resolver.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { ChangePasswordDialogComponent } from '../components/change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  IsLoading: boolean = false;
  hide: boolean = true;
  resp: any = undefined;
  rememberMe = false;

  submitted: boolean = false;
  loginError: string = '';
  loginForm: FormGroup = new FormGroup({});

  @ViewChild('ChangePwdDialog_Ref') ChangePwdDialog_Ref: ChangePasswordDialogComponent;

  constructor(
    private router: Router,
    private httpService: ApiHttpService,
    private ApienvService: ApienvService,
    private profileService: ProfileService,
    private el: ElementRef,
    private UILoader: NgxSpinnerService,
    private dataResolver: DataResolverService,
    private cdr: ChangeDetectorRef,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.initializeForm();

    this.cdr.detectChanges();
  }

  initializeForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      remember_me: new FormControl(false, [Validators.required]),
    });
  }


  async handleSubmit() {
    this.UILoader.show();
    this.submitted = true;
    this.focusIN();
    if (
      this.loginForm.get('email')?.valid &&
      this.loginForm.get('password')?.valid
    ) {
      this.IsLoading = true;
      // this.cdr.detectChanges();
      // // this payload needs to be send on the server.
      this.resp = await this.httpService.post('/login', this.loginForm.value);
      
      if (this.resp.status == 200) {
        this.IsLoading = false;
        if (this.resp?.data?.original?.user?.init_password) {
          this.ChangePwdDialog_Ref.show(this.resp?.data?.original);
        }
        else {
          this.profileService.settoken(this.resp?.data?.original.access_token);
          if (this.resp?.data?.original?.user?.permissions) {
            let response = JSON.parse(this.resp?.data?.original?.user?.permissions);
            localStorage.setItem(
              'user_permissions',
              JSON.stringify(this.permissionsService.transform(response))
            );

            this.profileService.setPermissions();
            this.profileService.setMenus();
          }
          this.navigateToFirstMenu();
        }
        
        
        // this.UILoader.hide();
        //this.router.navigate(['/setup/services/list']);
      } else {
        this.IsLoading = false;
        this.submitted = false;
        this.loginError = this.resp.error;
      }
    }
  }

  navigateToFirstMenu(){
    this.UILoader.hide();
    if(this.profileService.items.length){
      let menuItems:MenuItem[] = this.profileService.items;
      if(menuItems[0].styleClass == "has-childs"){
        this.router.navigate([menuItems[0].items[0].routerLink[0]]);
      }
      else{
        this.router.navigate([menuItems[0].routerLink[0]]);
      }
    }
    else{
     // console.log(this.profileService.items);
      
    }
  }

  focusIN() {
    const invalidControl = this.el.nativeElement.querySelector('input');
    if (invalidControl) {
      invalidControl.focus();
    }
    this.resp = undefined;
  }
}

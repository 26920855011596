import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { ProfileService } from 'src/app/core/services/profile.service';

@Component({
  selector: 'change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  visible: boolean = false;
  usersForm: any;
  dialog_header: string = "Export";
  PassEyeIconHide: boolean = true;
  confirmPassEyeIconHide: boolean = true;
  is_form_submit: boolean = false;
  resp: any = undefined;
  user_info: any;
  token: any;
  api_resp: any;

  constructor(private fb: FormBuilder, public dialogService: DialogService, private messageService: MessageService,
    private httpService: ApiHttpService, private profileService: ProfileService, private permissionsService: PermissionsService, private UILoader: NgxSpinnerService,
    private router: Router,) { }


  ngOnInit(): void {
    this.initializeForm();
  }


  initializeForm() {
    this.usersForm = this.fb.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), this.patternValidator(/\d/, { hasNumber: true }),
      this.patternValidator(/[A-Z]/, { hasUpperCase: true }),
      this.patternValidator(/[a-z]/, { hasLowerCase: true }),
      this.patternValidator(/[@$!%*?&]/, { hasSpecialCharacter: true }),])],
      confirm_password: ['', Validators.compose([Validators.required])],
    });
  }

  async submit() {
    this.profileService.settoken(this.token);
    this.resp = await this.httpService.put(`/user_password_update/${this.user_info?.id}`, this.usersForm.value);

    if (this.resp.status == 200) {
      localStorage.removeItem('access_token');
      this.visible = false;
    }
    else{
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: this.resp?.error?.message, key: 'app_notiy' });
    }
  }

  show(resp: any) {
    this.initializeForm();
    this.api_resp = resp;
    this.user_info = resp?.user;
    this.token = resp?.access_token;
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  patternValidator(regex: RegExp, error: { [key: string]: boolean }) {
    return (control: any) => {
      if (!control.value || regex.test(control.value)) {
        return null;
      }
      return error;
    };
  }

  getErrorMessages(): string[] {
    const errors: string[] = [];
    if (this.usersForm.controls['password']?.hasError('required')) {
      errors.push('Password is required.');
    }
    if (this.usersForm.controls['password']?.hasError('minlength')) {
      errors.push('Password must be at least 8 characters long.');
    }
    if (this.usersForm.controls['password']?.hasError('hasNumber')) {
      errors.push('Password must include at least one number.');
    }
    if (this.usersForm.controls['password']?.hasError('hasUpperCase')) {
      errors.push('Password must include at least one uppercase letter.');
    }
    if (this.usersForm.controls['password']?.hasError('hasLowerCase')) {
      errors.push('Password must include at least one lowercase letter.');
    }
    if (this.usersForm.controls['password']?.hasError('hasSpecialCharacter')) {
      errors.push('Password must include at least one special character (e.g., @$!%*?&).');
    }
    return errors;
  }
}
